import axios from "axios"
import { ReturningGuestData, SelfCheckIn } from "@/api/interface/self-check-in"
import { Media } from "@/api/interface/media"
import { Location } from "../interface/location"

const getIncludes = ["sciable", "geofence", "schedule", "schedule.items"]

const parseSettings = (settings: string | object) =>
  typeof settings === "string" ? JSON.parse(settings) : settings

const stringifySettings = (settings: string | object) =>
  typeof settings === "string" ? settings : JSON.stringify(settings)

export const getSelfCheckIns = (): Promise<Array<SelfCheckIn>> =>
  axios
    .get(`v2/self-check-ins`, {
      params: { include: getIncludes, filter: { trashed: "with" } },
    })
    .then((res) =>
      res.data.data?.map((x: SelfCheckIn) => ({
        ...x,
        settings: parseSettings(x.settings),
      }))
    )

export const getPublicSelfCheckIn = (id: SelfCheckIn["id"]): Promise<any> =>
  axios
    .get(`v2/public/check-in/${id}`, { params: { include: getIncludes } })
    .then((res) => ({
      ...res.data.data,
      settings: parseSettings(res.data.data.settings),
    }))

export const createPublicSelfCheckin = (selfCheckIn: Partial<SelfCheckIn>, locationId: Location['id']) =>
  axios.post(`v2/public/check-in/${locationId}/create-party`, selfCheckIn)

export const createSelfCheckIn = (
  sci: Omit<SelfCheckIn, "id">
): Promise<SelfCheckIn> =>
  axios
    .post(`v2/self-check-ins`, {
      ...sci,
      settings: stringifySettings(sci.settings),
    })
    .then((res) => res.data.data)

export const updateSelfCheckIn = (
  sci: Partial<SelfCheckIn> & { id: SelfCheckIn["id"] }
): Promise<SelfCheckIn> => {

  // cannot ever update a sci if these are included
  delete sci.sciableId
  delete sci.sciableType

  return axios
  .patch(`v2/self-check-ins/${sci.id}`, {
    ...sci,
    settings: stringifySettings(sci.settings),
  })
  .then((res) => res.data.data)
}
  

export const deleteSelfCheckIn = (id: SelfCheckIn["id"]): Promise<any> =>
  axios.delete(`v2/self-check-ins/${id}`)

export const restoreSelfCheckIn = (id: SelfCheckIn["id"]): Promise<any> =>
  axios.patch(`v2/self-check-ins/${id}/restore`)

export const createSciMedia = async (
  id: SelfCheckIn["id"],
  media: FormData
): Promise<SelfCheckIn> =>
  axios
    .post(`v2/self-check-ins/${id}/media`, media, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data.data)

export const deleteSciMedia = async (
  id: SelfCheckIn["id"],
  mediaId: Media["id"]
) =>
  axios
    .delete(`v2/self-check-ins/${id}/media/${mediaId}`)
    .then((res) => res.data)

// Check if guest is returning or new by phone number
export const postGuestExists = async ({ locationId, phone }: { locationId: Location['id'], phone: string }): Promise<number> => {
  try {
    const res = await axios.post(`v2/public/check-in/${locationId}/guest-exists`, { phone })
    return res.status
  } catch (error) {
    console.error(error)
    return 404
  }
}
// Lookup returning guest data
export const getReturningGuestData = async ({ locationId, phone }): Promise<ReturningGuestData> => {
  return axios
    .post(`v2/public/check-in/${locationId}/guest-lookup`, { phone })
    .then(res => res.data.data)
}